<template>
  <MDBModal id="deductionModal" tabindex="-1" labelledby="deductionModalLabel"
    v-model="deductionStore.deductionsModalState">
    <MDBModalHeader class="px-4 py-3">
      <MDBModalTitle id="deductionModalTitle">
        {{ setModalTitle }}
      </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <div v-if="
        deductionStore.deductionsModalType == 'addDeduction' ||
        deductionStore.deductionsModalType == 'editDeduction'
      " class="mb-2">
        <MDBRow>
          <MDBCol>
            {{ addEditLabel }} adjustment for
            <strong>
              {{ getMonthFullFormat(deductionStore.deductionForm.month) }}
              {{ deductionStore.deductionForm.year }}
            </strong>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mt-2">
          <MDBCol>
            <MDBInput label="Adjustment Name" type="text" v-model="deductionStore.deductionForm.deductionName" />
          </MDBCol>
          <MDBCol>
            <MDBInput label="Adjustment Fee" type="number" v-model="deductionStore.deductionForm.fee" />
          </MDBCol>
        </MDBRow>
      </div>
      <div v-if="deductionStore.deductionsModalType == 'assignDefaultDeduction'" class="overflow-auto">
        <MDBTable striped hover class="align-middle">
          <thead class="table-dark">
            <tr>
              <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
                {{ item.header }}
              </th>
            </tr>
          </thead>
          <tbody v-if="defaultDeductions.data.length < 1">
            <tr class="text-center">
              <td colspan="3">
                <h5 class="m-auto">No Default Deductions Available</h5>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="defaultDeductions.data.length > 0">
            <tr v-for="(item, i) in defaultDeductions.data" :key="i">
              <td>{{ item.deductionName }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </MDBTable>
        <DeductionsPagination />
      </div>
      <div v-if="
        deductionStore.deductionsModalType == 'addDefaultDeduction' ||
        deductionStore.deductionsModalType == 'editDefaultDeduction'
      " class="mb-2">
        <MDBRow>
          <MDBCol> {{ addEditLabel }} default adjustment. </MDBCol>
        </MDBRow>
        <MDBRow class="mt-2">
          <MDBCol>
            <MDBInput label="Default Adjustment Name" type="text"
              v-model="deductionStore.defaultDeductionForm.deductionName" />
          </MDBCol>
          <MDBCol>
            <MDBInput label="Default Adjustment Fee" type="number"
              v-model="deductionStore.defaultDeductionForm.value" />
          </MDBCol>
        </MDBRow>
      </div>
    </MDBModalBody>
    <MDBModalFooter class="py-3 gap-2">
      <MDBBtn size="sm" @click="deductionStore.deductionsModalState = false">Close</MDBBtn>
      <MDBBtn size="sm" color="primary" @click="handleSaveBtn" :disabled="isSaveBtnDisabled">{{ setSaveBtnLabel }}
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script>
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTable,
} from "mdb-vue-ui-kit";
import { useDeductionsStore } from "../../store/deductionsStore";
import { computed } from "vue-demi";
import { PostDeductions } from "../../services/Deductions/PostDeductions";
import { PostDefaultDeductions } from "../../services/Deductions/PostDefaultDeductions";
import { PostAssignDefaultDeductions } from "../../services/Deductions/PostAssignDefaultDeductions";
import { PutDeductions } from "../../services/Deductions/PutDeductions";
import { PutDefaultDeductions } from "../../services/Deductions/PutDefaultDeductions";
import { useToastStore } from "../../store/toastStore";
import DeductionsPagination from "./DeductionsPagination.vue";
import { getMonthFullFormat } from "../../helpers/parseDate";

export default {
  components: {
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTable,
    DeductionsPagination,
  },
  setup() {
    const deductionStore = useDeductionsStore();
    const toastStore = useToastStore();
    const setModalTitle = computed(() => {
      if (deductionStore.deductionsModalType == "addDeduction") {
        return "Add Adjustment";
      } else if (
        deductionStore.deductionsModalType == "assignDefaultDeduction"
      ) {
        return "Assign Default Adjustment";
      } else if (deductionStore.deductionsModalType == "editDeduction") {
        return "Edit Adjustment";
      } else if (deductionStore.deductionsModalType == "addDefaultDeduction") {
        return "Add Default Adjustment";
      } else if (deductionStore.deductionsModalType == "editDefaultDeduction") {
        return "Edit Default Adjustment";
      }
    });
    const addEditLabel = computed(() => {
      if (
        deductionStore.deductionsModalType == "editDeduction" ||
        deductionStore.deductionsModalType == "editDefaultDeduction"
      ) {
        return "Edit";
      } else if (
        deductionStore.deductionsModalType == "addDeduction" ||
        deductionStore.deductionsModalType == "addDefaultDeduction"
      ) {
        return "Add";
      }
      return "Save Adjustment";
    });
    const setSaveBtnLabel = computed(() => {
      if (deductionStore.deductionsModalType == "addDeduction") {
        return "Save Adjustments";
      } else if (deductionStore.deductionsModalType == "addDefaultDeduction") {
        return "Save Default Adjustment";
      }
      return "Save";
    });
    const tableHeaders = computed(() => [
      {
        header: "Adjustment",
      },
      {
        header: "Fee",
      },
    ]);
    const isSaveBtnDisabled = computed(
      () =>
        deductionStore.defaultDeductions.data.length < 1 &&
        deductionStore.deductionsModalType == "assignDefaultDeduction" &&
        (deductionStore.deductionForm.deductionName == "" ||
          deductionStore.deductionForm.fee == "")
    );
    const defaultDeductions = deductionStore.defaultDeductions;
    const handleSaveBtn = () => {
      if (deductionStore.deductionsModalType == "addDeduction") {
        AddDeduction();
      } else if (deductionStore.deductionsModalType == "editDeduction") {
        EditDeduction();
      } else if (
        deductionStore.deductionsModalType == "assignDefaultDeduction"
      ) {
        AssignDefaultDeduction();
      } else if (deductionStore.deductionsModalType == "addDefaultDeduction") {
        AddDefaultDeduction();
      } else if (deductionStore.deductionsModalType == "editDefaultDeduction") {
        EditDefaultDeduction();
      }
    };
    const afterRequestActions = () => {
      deductionStore.deductionsModalState = false;
      deductionStore.deductionsModalType = "";
      deductionStore.deductionForm.deductionName = "";
      deductionStore.deductionForm.fee = "";
      deductionStore.getDeductions({
        UserId: deductionStore.deductionForm.userId,
        Month: deductionStore.deductionForm.month,
        Year: deductionStore.deductionForm.year,
      });
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastStore.toastStatus.success,
      };
    };
    const afterRequestDefaultActions = () => {
      deductionStore.deductionsModalState = false;
      deductionStore.deductionsModalType = "";
      deductionStore.deductionForm.deductionName = "";
      deductionStore.deductionForm.fee = "";
      deductionStore.getDefaultDeductions({
        UserId: deductionStore.deductionForm.userId,
      });
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastStore.toastStatus.success,
      };
    };
    const AddDeduction = () => {
      PostDeductions({
        UserId: deductionStore.deductionForm.userId,
        Month: deductionStore.deductionForm.month,
        Year: deductionStore.deductionForm.year,
        DeductionName: deductionStore.deductionForm.deductionName,
        Fee: deductionStore.deductionForm.fee,
      })
        .then(() => {
          afterRequestActions();
        })
        .catch(() => {
          toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
          };
        });
    };
    const EditDeduction = () => {
      PutDeductions({
        Id: deductionStore.deductionForm.id,
        Month: deductionStore.deductionForm.month,
        Year: deductionStore.deductionForm.year,
        DeductionName: deductionStore.deductionForm.deductionName,
        Value: deductionStore.deductionForm.fee,
      })
        .then(() => {
          afterRequestActions();
        })
        .catch(() => {
          toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
          };
        });
    };

    const AddDefaultDeduction = () => {
      PostDefaultDeductions({
        UserId: deductionStore.defaultDeductionForm.userId,
        DeductionName: deductionStore.defaultDeductionForm.deductionName,
        Value: deductionStore.defaultDeductionForm.value,
      })
        .then(() => {
          afterRequestDefaultActions();
        })
        .catch(() => {
          toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
          };
        });
    };
    const EditDefaultDeduction = () => {
      PutDefaultDeductions({
        Id: deductionStore.defaultDeductionForm.id,
        DeductionName: deductionStore.defaultDeductionForm.deductionName,
        Value: deductionStore.defaultDeductionForm.value,
      })
        .then(() => {
          afterRequestDefaultActions();
        })
        .catch(() => {
          toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
          };
        });
    };
    const AssignDefaultDeduction = () => {
      PostAssignDefaultDeductions({
        UserId: deductionStore.deductionForm.userId,
        Month: deductionStore.deductionForm.month,
        Year: deductionStore.deductionForm.year,
      })
        .then(() => {
          afterRequestActions();
          afterRequestDefaultActions();
        })
        .catch(() => {
          toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
          };
        });
    };
    return {
      deductionStore,
      setModalTitle,
      setSaveBtnLabel,
      handleSaveBtn,
      AddDeduction,
      tableHeaders,
      defaultDeductions,
      isSaveBtnDisabled,
      addEditLabel,
      getMonthFullFormat,
    };
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}

.btn-outline-primary {
  border-color: var(--accent);
  color: var(--accent);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>
