import { HttpClient } from "../HttpClient";
/**
 * * Post Default Deductions
 */
export const PostAssignDefaultDeductions = async (deductionData) => {
	try {
		const { data } = await HttpClient.post("api/assetRevenueDeductions/assign", { ...deductionData });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
