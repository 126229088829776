import { HttpClient } from "../HttpClient";
/**
 * * Post Default Deductions
 */
export const PostDefaultDeductions = async (deductionData) => {
	try {
		const { data } = await HttpClient.post("api/defaultDeductions", { ...deductionData });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
