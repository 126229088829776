import { HttpClient } from "../HttpClient";
/**
 * * Patch Deductions
 */
export const PatchDeductions = async (id, isDeleted) => {
	try {
		const { data } = await HttpClient.patch(`api/assetRevenueDeductions/${id}/Status`, { ...isDeleted });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
