import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { GetDeductions } from "../services/Deductions/GetDeductions";
import { GetDefaultDeductions } from "../services/Deductions/GetDefaultDeductions";
import { useDeductionsPaginationStore } from "../store/deductionsPaginationStore";

export const useDeductionsStore = defineStore("deductions", () => {
	const deductionsPaginationStore = useDeductionsPaginationStore();
	const userName = ref({
		firstName: "",
		lastName: "",
	});
	const deductions = ref({
		data: null,
	});
	const defaultDeductions = ref({
		data: [],
	});
	const loading = ref(false);
	const deductionsModalState = ref(false);
	const deductionsModalType = ref("");
	const deductionForm = ref({
		id: "",
		month: "",
		year: "",
		deductionName: "",
		fee: "",
		userId: "",
	});
	const defaultDeductionForm = ref({
		id: "",
		deductionName: "",
		fee: "",
		userId: "",
		value: "",
	});

	const getDeductions = (payload) => {
		deductions.value.data = null;
		loading.value = true;
		GetDeductions(payload?.UserId, payload?.Month, payload?.Year, payload?.pageNumber).then((response) => {
			deductions.value.data = response.data;
			deductionsPaginationStore.pagination = response.pagination;
			loading.value = false;
		});
	};
	const getDefaultDeductions = (payload) => {
		loading.value = true;
		GetDefaultDeductions(payload?.UserId, payload?.pageNumber).then((response) => {
			defaultDeductions.value.data = response.data;
			deductionsPaginationStore.pagination = response.pagination;
			loading.value = false;
		});
	};
	watch(
		() => deductionsPaginationStore.pagination.currentPage,
		(newCurrentPage) => {
			console.log("pagination", newCurrentPage);
		}
	);
	return {
		deductions,
		getDeductions,
		loading,
		deductionsModalState,
		deductionsModalType,
		deductionForm,
		defaultDeductions,
		getDefaultDeductions,
		defaultDeductionForm,
		userName,
	};
});
