<template>
	<MDBTable striped hover class="align-middle">
		<thead class="table-dark">
			<tr>
				<th v-for="(item, i) in tableHeaders" :key="i" scope="col">
					{{ item.header }}
				</th>
			</tr>
		</thead>
		<tbody v-if="deductionsStore.deductions.data === null">
			<tr class="text-center">
				<td colspan="3">
					<h5>Select a month and a year.</h5>
				</td>
			</tr>
		</tbody>
		<tbody v-else-if="deductionsStore.deductions.data.length < 1">
			<tr class="text-center">
				<td colspan="3">
					<h5>No adjustments available.</h5>
				</td>
			</tr>
		</tbody>
		<tbody v-else-if="deductionsStore.deductions.data.length > 0">
			<tr v-for="(item, i) in deductionsStore.deductions.data" :key="i">
				<td>{{ item.deductionName }}</td>
				<td>{{ item.fee }}</td>
				<td v-if="userDetail.userTypeId == 1">
					<button class="message-btn btn ms-2 btn-outline-primary btn-floating btn-sm"
						@click.stop="clickAction(item, 'edit')">
						<em class="fa fa-pen"></em>
					</button>
					<button class="trash-btn btn ms-2 btn-outline-primary btn-floating btn-sm"
						@click.stop="clickAction(item, 'delete')">
						<em class="fa fa-trash" />
					</button>
				</td>
			</tr>
		</tbody>
	</MDBTable>
	<DeductionsPagination />
</template>

<script>
import { MDBTable } from "mdb-vue-ui-kit";
import { computed } from "vue";
import { PatchDeductions } from "../../services/Deductions/PatchDeductions";
import { useDeductionsStore } from "../../store/deductionsStore";
import DeductionsPagination from "./DeductionsPagination.vue";
import { useUserDetailStore } from "../../store/userDetailStore";
import { storeToRefs } from "pinia";

export default {
	components: {
		MDBTable,
		DeductionsPagination,
	},
	setup() {
		const userDetailStore = useUserDetailStore();
		const { userDetail } = storeToRefs(userDetailStore);
		const deductionsStore = useDeductionsStore();
		const tableHeaders = computed(() => {
			if (userDetail.value.userTypeId == 1) {
				return [
					{
						header: "Adjustment",
					},
					{
						header: "Fee",
					},
					{
						header: "Actions",
					},
				];
			} else {
				return [
					{
						header: "Adjustment",
					},
					{
						header: "Fee",
					},
				];
			}
		});
		const clickAction = (item, type) => {
			if (type === "delete") {
				deleteUser(item);
			} else if (type === "edit") {
				editUser(item);
			}
		};
		const deleteUser = (item) => {
			PatchDeductions(item.id, { IsDeleted: true }).then(
				setTimeout(() => {
					deductionsStore.getDeductions({
						UserId: deductionsStore.deductionForm.userId,
						Month: deductionsStore.deductionForm.month,
						Year: deductionsStore.deductionForm.year,
					});
				}, 500)
			);
		};
		const editUser = (item) => {
			deductionsStore.deductionsModalType = "editDeduction";
			deductionsStore.deductionsModalState = true;
			deductionsStore.deductionForm = { ...deductionsStore.deductionForm, ...item };
		};
		return {
			tableHeaders,
			clickAction,
			deductionsStore,
			userDetail,
		};
	},
};
</script>

<style lang="scss" scoped>
.table {
	min-height: 18.75rem;
}
</style>
