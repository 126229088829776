import { HttpClient } from "../HttpClient";
/**
 * * Put Default Deductions
 */
export const PutDefaultDeductions = async (deductionData) => {
	try {
		const { data } = await HttpClient.put("api/defaultDeductions", { ...deductionData });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
