import { HttpClient } from "../HttpClient";
/**
 * * Get Deductions
 */
export const GetDeductions = async (id, month, year, pageNumber = 1, rowsPerPage = 10) => {
	try {
		const { data } = await HttpClient.get("api/assetRevenueDeductions", {
			params: {
				PageNumber: pageNumber,
				RowsPerPage: rowsPerPage,
				...(id ? { UserId: id } : {}),
				...(month ? { Month: month } : {}),
				...(year ? { Year: year } : {}),
			},
		});
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
