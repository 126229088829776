<template>
  <LayoutNew>
    <MDBCard id="Adjustments" class="mb-2" tag="form">
      <MDBCardHeader class="p-4">
        <h4 class="mb-0">Adjustments</h4>
        <p class="mb-0">
          <small>View, add, and assign adjustments for
            <strong class="text-capitalize">
              {{ userDetail.firstName }}
              {{ userDetail.lastName }}
            </strong>
            .</small>
        </p>
      </MDBCardHeader>
      <MDBCardBody class="shadow-lg overflow-auto">
        <DeductionsComponent />
      </MDBCardBody>
    </MDBCard>
  </LayoutNew>
</template>

<script setup>
import { MDBCard, MDBCardHeader, MDBCardBody } from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew";
import DeductionsComponent from "@/components/Deductions/DeductionsComponent.vue";
import { useDeductionsStore } from "@/store/deductionsStore";
import { ref, defineProps } from "vue";
import { useSendParamsDetails } from "@/store/sendParamsDetails";
import { storeToRefs } from "pinia";
import { useTitle } from "@vueuse/core";

useTitle("User Adjustments | CreatorShield");

const props = defineProps(["firstName", "lastName"]);

const deductionsStore = useDeductionsStore();
const sendParamsDetails = useSendParamsDetails();
const { userDetail } = storeToRefs(sendParamsDetails);
deductionsStore.userName = {
  firstName: ref(props.firstName),
  lastName: ref(props.lastName),
};
</script>

<style scoped>
#Adjustments {
  color: var(--accent);
  font-family: "Poppins", sans-serif;
}

.btn-primary {
  background-color: var(--primary);
}

.btn-secondary {
  background-color: var(--accent);
}

.card,
.card-body {
  overflow-y: auto;
}
</style>
