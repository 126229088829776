import { HttpClient } from "../HttpClient";
/**
 * * Get Default Deductions
 */
export const GetDefaultDeductions = async (id) => {
	try {
		const { data } = await HttpClient.get("api/defaultDeductions", {
			params: {
				...(id ? { UserId: id } : {}),
			},
		});
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
