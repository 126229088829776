import { defineStore } from "pinia";
import { ref } from "vue";

export const useSendParamsDetails = defineStore(
	"sendParamsDetails",
	() => {
		const userDetail = ref({
			firstName: "",
			lastName: "",
		});
		return {
			userDetail,
		};
	},
	{
		persist: true,
	}
);
