<template>
  <MDBLoading v-model="deductionsStore.loading" class="adjustment-loader" loadingText="" icon="spinner-grow" />
  <MDBTabs v-model="adjustmentTab">
    <!-- Tabs navs -->
    <MDBTabNav tabsClasses="mb-3">
      <MDBTabItem tabId="defaultAdjustments" href="defaultAdjustments" v-if="userDetail.userTypeId == 1">
        Default Adjustment
      </MDBTabItem>
      <MDBTabItem tabId="assetRevenueAdjustments" href="assetRevenueAdjustments">Asset Revenue Adjustment</MDBTabItem>
    </MDBTabNav>
    <!-- Tabs content -->
    <MDBTabContent>
      <MDBTabPane tabId="defaultAdjustments" v-if="userDetail.userTypeId == 1">
        <div class="d-grid gap-4">
          <MDBRow v-if="userDetail.userTypeId == 1">
            <MDBCol>
              <MDBBtn size="sm" color="primary" @click="handleModalState('addDefaultDeduction')">
                Add Default Adjustment
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow class="justify-content-center">
            <MDBCol>
              <MDBCard text="center bg-light">
                <DefaultAdjustmentsTable />
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBTabPane>
      <MDBTabPane tabId="assetRevenueAdjustments">
        <div class="d-grid gap-4">
          <MDBRow>
            <MDBCol>
              <MDBSelect v-model:options="monthsOption" v-model:selected="deductionsStore.deductionForm.month"
                label="Month" />
            </MDBCol>
            <MDBCol>
              <MDBSelect v-model:options="yearsOption" v-model:selected="deductionsStore.deductionForm.year"
                label="Year" />
            </MDBCol>
            <MDBCol>
              <MDBBtn color="primary" @click="handleDeductionSearch">
                Search
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow v-if="userDetail.userTypeId == 1">
            <MDBCol class="overflow-auto text-nowrap">
              <MDBBtn size="sm" color="primary" @click="handleModalState('addDeduction')"
                :disabled="disableAddDeductions">
                Add Adjustment
              </MDBBtn>
              <MDBBtn size="sm" color="primary" @click="handleModalState('assignDefaultDeduction')">
                Assign Default Adjustments
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow class="justify-content-center">
            <MDBCol>
              <MDBCard text="center bg-light">
                <DeductionsTable />
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBTabPane>
    </MDBTabContent>
  </MDBTabs>

  <DeductionsModal />
  <DeductionsToast />
</template>

<script setup>
import {
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBBtn,
  MDBLoading,
  MDBCard,
  MDBTabs,
  MDBTabNav,
  MDBTabContent,
  MDBTabItem,
  MDBTabPane,
} from "mdb-vue-ui-kit";
import DeductionsTable from "./DeductionsTable.vue";
import DeductionsModal from "./DeductionsModal.vue";
import { useDeductionsStore } from "../../store/deductionsStore";
import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "@vue/runtime-core";
import DeductionsToast from "./DeductionsToast.vue";
import DefaultAdjustmentsTable from "./DefaultAdjustmentsTable.vue";
import { useUserDetailStore } from "../../store/userDetailStore";
import { storeToRefs } from "pinia";

onMounted(() => {
  if (userDetailStore.userDetail.userTypeId != 1) {
    adjustmentTab.value = "assetRevenueAdjustments";
  }
});

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);
const route = useRoute();
const userId = route.params.id;
const deductionsStore = useDeductionsStore();
deductionsStore.deductionForm.userId = userId;
deductionsStore.defaultDeductionForm.userId = userId;
const adjustmentTab = ref("defaultAdjustments");

const monthsOption = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

const yearsOption = [];
const createYearsOption = () => {
  for (let year = 2010; year <= 2100; year++) {
    yearsOption.push({
      text: year,
      value: year,
    });
  }
  return yearsOption;
};

createYearsOption();
const handleDeductionSearch = () => {
  deductionsStore.getDeductions({
    UserId: deductionsStore.deductionForm.userId,
    Month: deductionsStore.deductionForm.month,
    Year: deductionsStore.deductionForm.year,
  });
};

const handleModalState = (modalType) => {
  if (modalType == "addDeduction") {
    deductionsStore.deductionsModalType = "";
    deductionsStore.deductionsModalType = "addDeduction";
    deductionsStore.deductionsModalState = true;
  } else if (modalType == "assignDefaultDeduction") {
    deductionsStore.deductionsModalType = "";
    deductionsStore.deductionsModalType = "assignDefaultDeduction";
    deductionsStore.getDefaultDeductions({
      UserId: deductionsStore.deductionForm.userId,
    });
    deductionsStore.deductionsModalState = true;
  } else if (modalType == "addDefaultDeduction") {
    deductionsStore.deductionsModalType = "";
    deductionsStore.deductionsModalType = "addDefaultDeduction";
    deductionsStore.deductionsModalState = true;
  }
};

let disableAddDeductions = ref(true);
watch(
  () => deductionsStore.deductions.data,
  (newDeductionsData) => {
    if (newDeductionsData !== null) {
      disableAddDeductions.value = false;
    }
  }
);
</script>

<style lang="scss" scoped>
.btn-primary {
  background-color: var(--primary);
}

:deep(.nav-tabs) {
  border-color: var(--accent);
}
</style>

<style>
.adjustment-loader {
  color: var(--accent);
}

.nav-tabs .nav-link {
  color: var(--accent);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--primary);
  border-color: var(--primary);
}
</style>
