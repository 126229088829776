import { defineStore } from "pinia";

export const useDeductionsPaginationStore = defineStore("deductionsPagination", () => {
	const pagination = {
		currentPage: 0,
		itemsPerPage: 0,
		totalItems: 0,
		totalPages: 0,
	};
	return { pagination };
});
